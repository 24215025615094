@import '../../common/reset.css';

.imageBlogBlock {
    background: #10A881;
    padding: 2%;
}

.info {
    text-align: center;
    color: #FFF;
    margin-bottom: 2%;
    font-size: 24px;
    line-height: 28px;
}

.cardMainBlock ul {
    display: flex;
}

.cardMainBlock li {
    width: 32%;
    margin-left: 2%;
}

.cardMainBlock li:first-child {
    margin-left: 0;
}
