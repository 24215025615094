@import '../../common/reset.css';

.navBar {
    width: calc(100% - 75px);
    padding: 8px 0;
}

.navBarList {
    display: flex;
    justify-content: flex-end;
}

.navBarList .link {
    background: none;
    border: 0;
    box-shadow: none;
    margin: 8px;
    cursor: pointer;
}

.navBarList .link:hover {
    text-decoration: underline;
}
